import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import BasketServiceContext from "../context/BasketServiceContext";

function Product(props) {
    const goods = props.goods;
    const basketService = useContext(BasketServiceContext);
    const count = basketService.getCountGoods(goods.id);
    return <div className="products__grid-item">
        <div className="product-mini">
            <Link to={`/goods/${goods.id}`} className="product-mini__picture">
                <img src={goods.image} alt="" title=""
                     className="product-mini__picture-img"/>
                {goods.weight.length > 0 &&
                    <div className="product-mini__detail">
                        {goods.weight.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {goods.weightLabel ? goods.weightLabel : 'г'}
                    </div>
                }
            </Link>
            <div className="product-mini__data">
                <div className="product-mini__title">
                    <Link to={`/goods/${goods.id}`} className="product-mini__title-link">
                        {goods.name}
                    </Link>
                </div>
                <div className="product-mini__grid product-mini__grid_1">
                    <div className="product-mini__grid-item product-mini__grid-item_1">
                        <div className="product-mini__price">
                            <button className="button-style-reset product-mini__price-item"
                                    onClick={(e) => basketService.addGoods(goods)}
                            >
                                {goods.price} ₽
                                <svg className="icon-svg product-mini__price-icon-cart">
                                    <use xlinkHref="#icon-cart-1"></use>
                                </svg>
                            </button>
                        </div>
                    </div>
                    {count > 0 &&
                        <div className="product-mini__grid-item product-mini__grid-item_2">
                            <div className="amount-mini amount-mini_1 product-mini__amount">
                                <input type="text"
                                       value={count}
                                       className="amount-mini__input-text"
                                       disabled
                                />
                                <button
                                    className="amount-mini__button amount-mini__button_decrement"
                                    onClick={(e) => basketService.delGoods(goods.id)}
                                />
                                <button
                                    className="amount-mini__button amount-mini__button_increment"
                                    onClick={(e) => basketService.addGoods(goods)}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
}
export default Product;