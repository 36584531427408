import React, {useEffect, useState} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Presentation from "./components/pages/Presentation";
import About from "./components/pages/About";
import Privacy from "./components/pages/Privacy";
import RulesPrivacy from "./components/pages/RulesPrivacy";
import IsAdult from "./components/pages/IsAdult";
import Location from "./components/pages/Location";
import Page404 from "./components/pages/Page404";
import Auth from "./components/pages/Auth";
import Catalog from "./components/pages/Catalog";
import Goods from "./components/pages/Goods";
import CabinetOrders from "./components/pages/CabinetOrders";
import CabinetOrder from "./components/pages/CabinetOrder";
import Help from "./components/pages/Help";
import BasketServiceContext from "./components/context/BasketServiceContext";
import CheckoutPayType from "./components/pages/CheckoutPayType";
import CheckoutSuccess from "./components/pages/CheckoutSuccess";
import SignInForm from "./components/blocks/SignInForm";
import {useUser} from "reactfire";

function App() {
    const {data: user} = useUser();

    // Показ презентации
    const [presentationShown, setPresentationShown] = useState(localStorage.getItem('presentationShown'));
    function onChangePresentationShown() {
        const val = '1';
        localStorage.setItem('presentationShown', val);
        setPresentationShown(val);
    }

    // Показ 18+
    const [isAdult, setIsAdult] = useState(localStorage.getItem('isAdult'));
    function onChangeIsAdult(val) {
        localStorage.setItem('isAdult', val);
        setIsAdult(val);
    }

    // basket
    const [basket, setBasket] = useState(localStorage.getItem('basket') ? JSON.parse(localStorage.getItem('basket')) : []);
    function onChangeBasket(basketNew) {
        localStorage.setItem('basket', JSON.stringify(basketNew));
        setBasket(basketNew);
    }

    // locationId
    const [locationId, setLocationId] = useState(localStorage.getItem('locationId'));
    function onChangeLocationId(locationIdNew) {
        // Сброс корзины, при изменении локации.
        if (locationId !== locationIdNew) {
            onChangeBasket([]);
        }
        localStorage.setItem('locationId', locationIdNew);
        setLocationId(locationIdNew);
    }
    const locationIdGet = new URLSearchParams(window.location.search).get('locationId');
    if (locationIdGet && locationIdGet !== locationId) {
        onChangeLocationId(locationIdGet);
    }

    // placeId
    const [placeId, setPlaceId] = useState(localStorage.getItem('placeId'));
    function onChangePlaceId(placeIdNew) {
        localStorage.setItem('placeId', placeIdNew);
        setPlaceId(placeIdNew);
    }
    const placeIdGet = new URLSearchParams(window.location.search).get('placeId');
    if (placeIdGet && placeIdGet !== placeId) {
        onChangePlaceId(placeIdGet);
    }

    // Сервис корзина
    const basketService = {
        basket,
        locationId,
        placeId,
        isAdult,
        getCountGoods: function (goodsId) {
            const item = basket.find(item => item.id === goodsId);
            return item ? item.count : 0;
        },
        getCountPositions: function() {
            return basket.length;
        },
        addGoods: function (goods) {
            const cloneBasket = [...basket];
            const item = cloneBasket.find(item => item.id === goods.id);
            if (item) {
                item.count++;
            } else {
                cloneBasket.push({id: goods.id, count: 1, goods});
            }
            onChangeBasket(cloneBasket);
        },
        delGoods: function (goodsId) {
            let cloneBasket = [...basket];
            const item = cloneBasket.find(item => item.id === goodsId);
            if (item) {
                item.count--;
                if (item.count < 1) {
                    cloneBasket = cloneBasket.filter(item => item.id !== goodsId)
                }
            }
            onChangeBasket(cloneBasket);
        },
        resetBasket: function() {
            onChangeBasket([]);
        }

    };

    /*console.log('presentationShown', presentationShown);
    console.log('isAdult', isAdult);
    console.log('locationId', locationId);
    console.log('placeId', placeId);
    console.log('basketService', basketService);*/

    if (window.location.pathname === '/smolenskaya')
    {
        window.location = '/?locationId=1';
        return ''
    }

    // Замена контента на 404, презентацию, 18+.
    let content;
    if (!locationId) {
        content = <Page404/>
    } else if (!presentationShown) {
        content = <Presentation onChangePresentationShown={onChangePresentationShown}/>
    } else if (isAdult === null) {
        content = <IsAdult onChangeIsAdult={onChangeIsAdult}/>
    } else {
        content = (
            <Switch>
                {/* dev routes */}
                {/*<Route exact strict path="/presentation">
                    <Presentation onChangePresentationShown={onChangePresentationShown}/>
                </Route>

                <Route exact strict path="/is-adult">
                    <IsAdult onChangeIsAdult={onChangeIsAdult}/>
                </Route>*/}

                {/* prod routes */}
                <Route exact strict path="/">
                    <Location locationId={locationId}
                              placeId={placeId}
                              isAdult={isAdult}
                    />
                </Route>
                <Route exact strict path="/catalog/:catId?" >
                    <Catalog locationId={locationId}
                             placeId={placeId}
                             isAdult={isAdult}
                    />
                </Route>
                <Route exact strict path="/goods/:goodsId" >
                    <Goods
                        placeId={placeId}
                        isAdult={isAdult}
                    />
                </Route>
                <Route exact strict path="/cabinet/orders" component={CabinetOrders} />
                <Route exact strict path="/cabinet/orders/:orderId" component={CabinetOrder} />
                <Route exact strict path="/checkout/paytype" component={CheckoutPayType} />
                <Route exact strict path="/checkout/contact" component={Auth} />
                <Route exact strict path="/checkout/success" component={CheckoutSuccess} />

                <Route exact strict path="/pages/about" component={About} />
                <Route exact strict path="/pages/privacy" component={Privacy} />
                <Route exact strict path="/pages/rulesPrivacy" component={RulesPrivacy} />
                <Route exact strict path="/pages/help" component={Help} />
                {/*<Route path="/404" component={Page404} />*/}

                {/* Редиректы */}
                {/*<Redirect exact strict from="/spb" to="/?locationId=1" />*/}

                <Route component={Page404} />
            </Switch>
        );
    }

    return (
        <BasketServiceContext.Provider value={basketService}>
            <Router>
                <Switch>
                    {/* Отображается независимо от показа презентации, 18+, locationId */}
                    <Route exact strict path="/dev">
                        <Dev presentationShown={presentationShown}
                             isAdult={isAdult}
                             locationId={locationId}
                             placeId={placeId}
                             basket={basket}
                        />
                    </Route>
                    {content}
                </Switch>
            </Router>
        </BasketServiceContext.Provider>
    );
}

function Dev(props) {
    return (
        <nav>
            <ul>
                {/* dev routes */}
                {/*<li>
                    <Link to="/presentation">/presentation</Link>
                </li>
                <li>
                    <Link to="/is-adult">/is-adult</Link>
                </li>*/}

                {/* prod routes */}
                <li>
                    <a href="/?locationId=1&placeId=1">/?locationId=1&placeId=1</a>
                </li>
                <li>
                    <Link to="/catalog">/catalog</Link>
                </li>
                <li>
                    <Link to="/cabinet/orders">/cabinet/orders</Link>
                </li>
                <li>
                    <Link to="/checkout/paytype">/checkout/paytype</Link>
                </li>

                <li>
                    <Link to="/pages/about">/pages/about</Link>
                </li>
                <li>
                    <Link to="/pages/privacy">/pages/privacy</Link>
                </li>
                <li>
                    <Link to="/pages/rulesPrivacy">/pages/rulesPrivacy</Link>
                </li>
                <li>
                    <Link to="/pages/help">/pages/help</Link>
                </li>


                <li>
                    <button onClick={()=>{localStorage.clear()}}>localStorage.clear()</button>
                </li>

                <li>presentationShown: "{props.presentationShown}"</li>
                <li>isAdult: "{props.isAdult}"</li>
                <li>locationId: "{props.locationId}"</li>
                <li>placeId: "{props.placeId}"</li>
                <li>basket:
                    <pre>
                        {JSON.stringify(props.basket, null, 2)}
                    </pre>
                </li>
            </ul>
        </nav>
    );
}
export default App;
