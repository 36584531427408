import React, {useEffect, useContext, useState} from 'react';
import {Link, Redirect, useHistory} from "react-router-dom";
// import InputMask from "react-input-mask";
import Header from "../layout/Header";
import Nav from "../layout/Nav";
import Page404 from "./Page404";
import Loading from "./Loading";
import BasketServiceContext from "../context/BasketServiceContext";
import api from "../../utils/api";
import {useAuth, useUser} from 'reactfire';
import SignInForm from '../blocks/SignInForm'
//import UserDetails from '../blocks/UserDetails'

function CheckoutPayType() {
  const [location, setLocation] = useState(null);
  const [profile, setProfile] = useState(null);
  const [place, setPlace] = useState(null);
  const [payTypes, setPayTypes] = useState(null);
  const [page404, setPage404] = useState(false);
  const [pageSuccess, setPageSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const basketService = useContext(BasketServiceContext);
  const history = useHistory();
  const auth = useAuth();

  const {status, data: user, hasEmitted} = useUser();

  // Данные формы
  const [form, setForm] = useState(() => {
    return initialStateFormComputation();
  });

  function initialStateFormComputation() {
    let preorder = sessionStorage.getItem('preorder');
    if (preorder){
      return JSON.parse(preorder);
    } else {
      return({
        //basket: basketService.basket, // form.basket не обновляется при изменении basketService.basket
        locationId: basketService.locationId,
        placeId: basketService.placeId,
        isAdult: basketService.isAdult,
        placeText: '',
        payTypeId: null,
        description: '',
        name: '',
        guestCount: 1,
        //phone: '',
      });
    }
  }

  // Обработчики инпутов
  function onChangePlaceText(val) {
    const cloneForm = {...form};
    cloneForm.placeText = val;
    setForm(cloneForm);
  }

  function onChangeDescription(val) {
    const cloneForm = {...form};
    cloneForm.description = val;
    setForm(cloneForm);
  }

  function onChangePayTypeId(val) {
    const cloneForm = {...form};
    cloneForm.payTypeId = val;
    setForm(cloneForm);
  }

  function onChangeName() {
    auth.signOut();
    setProfile(null);
  }

  function onGuestMinus() {
    const cloneForm = {...form};
    let newGuestCount = cloneForm.guestCount - 1;
    if (newGuestCount > 0) {
      cloneForm.guestCount = newGuestCount;
    } else {
      cloneForm.guestCount = 1;
    }
    setForm(cloneForm);
  }

  function onGuestPlus() {
    const cloneForm = {...form};
    cloneForm.guestCount = cloneForm.guestCount + 1;
    setForm(cloneForm);
  }

  /*function onChangePhone(val) {
    const cloneForm = {...form};
    cloneForm.phone = val;
    setForm(cloneForm);
  }*/

  // Отправка формы
  async function onSubmit() {
    const cloneForm = {...form};
    if (user) {
      //const firebaseIdToken = await user.getIdToken();
      //console.log('firebaseIdToken', firebaseIdToken);
      cloneForm.basket = basketService.basket;
      //cloneForm.firebaseIdToken = firebaseIdToken;
      api.post('/orders', cloneForm).then((res) => {
        console.log('ok post /order', res);
        sessionStorage.setItem('orderSuccessId', res.data.id)
        basketService.resetBasket();
        setPageSuccess(true);
      }).catch(err => {
        console.log('err post /order', err.response);
        setErrors(err.response.data.errors);
      })
    } else {
      sessionStorage.setItem('preorder', JSON.stringify(cloneForm));
      history.push("/checkout/contact");
    }
  }

  function getProfile(){
    api.post(`/user/show`, {'uid': user.uid}).then(res => {
      if (res.data) {
        setProfile(res.data);
      } else {
        auth.signOut();
        setProfile(null);
      }
    }).catch(err => {
      auth.signOut();
      setProfile(null);
    })
  }

  // Загрузка данных
  // Данные пользователя (если авторизован)
  useEffect(() => {
    if (user) {
      getProfile();
    }
  }, []);
  // Локация
  useEffect(() => {
    api.get(`/locations/${basketService.locationId}`).then(res => {
      setLocation(res.data);
    }).catch(err => {
      setPage404(true);
    })
  }, [basketService.locationId]);
  // Место
  useEffect(() => {
    if (basketService.placeId && !form.placeText) {
      api.get(`/location-places/${basketService.placeId}`).then(res => {
        setPlace(res.data);
        onChangePlaceText(res.data.name);
      }).catch(err => {
        setPage404(true);
      })
    }
  }, [basketService.placeId]);
  // Способы оплаты
  useEffect(() => {
    api.get(`/order-pay-types`).then(res => {
      setPayTypes(res.data);
    }).catch(err => {
      setPage404(true);
    })
  }, []);
  // Title
  useEffect(() => {
    document.title = `Оформление заказа | Azimut Hotels`;
  }, []);
  // preorder
  useEffect(() => {
    sessionStorage.removeItem('preorder')
  }, []);
  // preorder
  // useEffect(() => {
  //   if (!user) {
  //     history.push("/checkout/contact");
  //   }
  // }, [user]);


  if (!user) {
    return <Redirect to="/checkout/contact"/>
  }

  // Page404, Loading
  if (page404) {
    return <Page404/>
  } else if (!location || (!form.placeText && basketService.placeId && !place) || !payTypes || status === 'loading' || hasEmitted === false) {
    return <Loading/>
  } else if (pageSuccess) {
    return <Redirect to="/checkout/success"/>
  }

  // Вычисление "Всего"
  let priceAmount = 0;
  let priceAmountOnlyBasket = 0;
  basketService.basket.forEach(basketItem => {
    priceAmountOnlyBasket += basketItem.count * basketItem.goods.price;
  });

  // Сервисный сбор
  let serviceFeePriceCalculate = 0;
  let serviceFeePriceCalculateTxt = '';
  if (location.serviceFeePercent) {
    serviceFeePriceCalculate = (priceAmountOnlyBasket * location.serviceFeePrice / 100).toFixed(2) * 1;
    serviceFeePriceCalculateTxt = `${serviceFeePriceCalculate} ₽ (${location.serviceFeePrice}% от суммы заказа)`;
  } else {
    serviceFeePriceCalculate = 1 * location.serviceFeePrice;
    serviceFeePriceCalculateTxt = `${serviceFeePriceCalculate} ₽`;
  }
  priceAmount = priceAmountOnlyBasket + serviceFeePriceCalculate;

  // Шаблоны
  const headerItem1 = (
    <Link to="/" className="button-back-top">
      <svg className="icon-svg button-back-top__icon">
        <use xlinkHref="#icon-arrow-3"/>
      </svg>
    </Link>
  );
  const headerItem3 = (
    <div className="title header__title">
      Детали заказа
    </div>
  );
  let nameBlock = null;
  if (profile){
     let phone = profile.phone.replace(new RegExp(`(^\\d{${profile.phone.length % 10}})(\\d{3})(\\d{3})(\\d{2})(\\d{2}$)`), '+$1 ($2) $3-$4-$5');
     form.name = profile.name;
     form.phone = phone;
     nameBlock = (
        <div className={`form__caption order__form-caption`}>
          Ваши данные<br/>
          Для подтверждения заказа и вежливого к Вам обращения.
          <br/>
          <br/>
          {profile.name} {phone}&nbsp;
          <a href='#' onClick={e => onChangeName()}>изменить</a>
        </div>
    );
  } else {
     nameBlock = (
        <div className={`form__caption order__form-caption`}>
          Ваши данные<br/>
          Для подтверждения заказа и вежливого к Вам обращения. Будут запрошены на следующем шаге
        </div>
    );
  }

  // Пустая корзина
  if (basketService.basket.length === 0) {
    return (
      <React.Fragment>
        <Header item1={headerItem1} item3={headerItem3}/>
        <Nav/>
        <div className="content">
          <div className="message message_cart-empty">
            <div className="cnt message__cnt">
              <div className="headline message__headline">
                <h2 className="headline__title">
                  Вы не добавили ни одной позиции.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>

      <Header item1={headerItem1} item3={headerItem3}/>
      <Nav/>

      <div className="content">
        <div className="order">
          <div className="cnt order__cnt">
            <div className="order__step order__step_details">
              <div className="form order__form">

                {/* Место/номер в ресторане/отеле */}
                <div className="form__item order__form-item">
                  <div
                    className={`form__caption order__form-caption ${errors['placeText'] ? 'form__caption_error' : ''}`}>
                    Ваш номер проживания
                  </div>
                  <div className="field-form form__field-form">
                    <input type="text"
                           className="input-text field-form__input-text mask-input-number"
                           value={form.placeText}
                           onChange={e => onChangePlaceText(e.target.value)}
                    />
                  </div>
                </div>

                {/* Количество гостей */}
                <div className="form__item order__form-item">
                  <div
                    className={`form__caption order__form-caption ${errors['placeText'] ? 'form__caption_error' : ''}`}>
                    Количество гостей
                  </div>
                  <div
                      className="product-mini-cart__grid-item product-mini-cart__grid-item_2"
                      style={ {width: '200px'} }>
                    <div className="amount-mini product-mini-cart__amount">
                      <input type="text"
                             value={form.guestCount}
                             className="amount-mini__input-text"
                             disabled
                      />
                      <button className="amount-mini__button amount-mini__button_decrement"
                              onClick={(e) => onGuestMinus()}
                      />
                      <button className="amount-mini__button amount-mini__button_increment"
                              onClick={(e) => onGuestPlus()}
                      />
                    </div>
                  </div>
                </div>

                {/* Способ оплаты */}
                <div className="form__item order__form-item order__form-item_pay">
                  <div
                    className={`form__caption order__form-caption ${errors['payTypeId'] ? 'form__caption_error' : ''}`}>
                    Способ оплаты
                  </div>
                  <div className="field-form form__field-form">
                    <div className="input-buttons">

                      {/* Список способов оплаты */}
                      {payTypes.map(payType => {
                        return (
                          <div className="input-buttons__item" key={payType.id}>
                            <label className="input-button label-input__input-button">
                              <input name="pay"
                                     type="radio"
                                     onChange={e => onChangePayTypeId(payType.id)}
                                     checked={(form.payTypeId === payType.id)}
                              />
                              <div className="input-button__icon">
                                <svg className="icon-svg input-button__icon-mark">
                                  <use xlinkHref="#icon-check"/>
                                </svg>
                              </div>
                              <div className="input-button__text">
                                {payType.name}
                              </div>
                            </label>
                          </div>
                        )
                      })}

                    </div>
                  </div>
                </div>

                {/* Комментарий к заказу */}
                <div className="form__item order__form-item">
                  <div
                    className={`form__caption order__form-caption ${errors['description'] ? 'form__caption_error' : ''}`}>
                    Комментарий к заказу
                  </div>
                  <div className="field-form form__field-form">
                                        <textarea className="input-text input-text_area field-form__input-text"
                                                  value={form.description}
                                                  onChange={e => onChangeDescription(e.target.value)}
                                        />
                  </div>
                </div>

                {/* Имя */}
                <div className="form__item order__form-item">
                  {nameBlock}
                </div>

                {/* Телефон */}
                {/*<div className="form__item order__form-item">
                  <div className="field-form form__field-form">
                    {/ *<input type="tel"
                                               className="input-text field-form__input-text mask-input-phone"
                                               placeholder="Номер телефона"
                                               value={form.phone}
                                               onChange={e => onChangePhone(e.target.value)}
                                        />* /}
                    {/ * https://github.com/sanniassin/react-input-mask * /}
                    <InputMask className="input-text field-form__input-text mask-input-phone"
                               mask="+7 (999) 999-99-99"
                               placeholder="Телефон"
                               value={form.phone}
                               onChange={e => onChangePhone(e.target.value)}
                    />
                  </div>
                </div>*/}

                {/* Состав заказа */}
                <div className="form__item order__form-item order__form-item_products">
                  <div className={`form__caption order__form-caption ${errors['basket'] ? 'form__caption_error' : ''}`}>
                    Состав заказа
                  </div>

                  <div className="order__products">
                    <div className="order__products-grid order__products-grid_1">

                      {/* Список продуктов в корзине */}
                      {basketService.basket.map(basketItem => {
                        console.log(basketItem);
                        return (
                          <div className="order__products-grid-item" key={basketItem.id}>
                            <div className="product-mini-cart">
                              <div className="product-mini-cart__grid product-mini-cart__grid_1">
                                <div
                                  className="product-mini-cart__grid-item product-mini-cart__grid-item_1">
                                  <div className="product-mini-cart__title">
                                    <Link to={`/goods/${basketItem.goods.id}`}
                                          className="product-mini-cart__title-link">
                                      {basketItem.goods.name}
                                    </Link>
                                  </div>
                                  <div className="product-mini-cart__price">
                                    {basketItem.goods.price} ₽
                                  </div>
                                </div>

                                <div
                                  className="product-mini-cart__grid-item product-mini-cart__grid-item_2">
                                  <div className="amount-mini product-mini-cart__amount"
                                       data-suffix=" шт">
                                    <input type="text"
                                           value={basketService.getCountGoods(basketItem.id) + ' шт'}
                                           className="amount-mini__input-text"
                                           disabled
                                    />
                                    <button className="amount-mini__button amount-mini__button_decrement"
                                            onClick={(e) => basketService.delGoods(basketItem.id)}
                                    />
                                    <button className="amount-mini__button amount-mini__button_increment"
                                            onClick={(e) => basketService.addGoods(basketItem.goods)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                    </div>
                  </div>

                  <div className="order__price">

                    {/* Сервисный сбор */}
                    {location.serviceFeePrice > 0 &&
                    <div className="order__price-item order__price-item_extra">
                      <div className="order__price-value">
                        {serviceFeePriceCalculateTxt}
                      </div>
                      <div className="order__price-caption">
                        {location.serviceFeeName}
                      </div>
                    </div>
                    }

                    <div className="order__price-item order__price-item_total">
                      <div className="order__price-value">
                        {priceAmount} ₽
                      </div>
                      <div className="order__price-caption">
                        Всего
                      </div>
                    </div>
                    {location.minOrderPrice > priceAmountOnlyBasket &&
                    <div className="order__price-item order__price-item_total">
                      <div className="order__price-value">
                        {location.minOrderPrice} ₽
                      </div>
                      <div className="order__price-caption">
                        Минимальная стоимость заказа
                      </div>
                    </div>
                    }
                  </div>
                </div>

                {/* Ошибки валидации */}
                {Object.keys(errors).length > 0 &&
                <div className="form__errors">
                  {Object.keys(errors).map(field => {
                    return errors[field].map((error, index) => {
                      return <div key={index}>{error}</div>
                    })
                  })}
                </div>
                }

                <div className="form__item order__form-item order__form-item_submit">
                  {/* Вход или Оформить заказ */}
                  <button type="submit"
                          className="button-style-reset button-a order__button-submit"
                          onClick={onSubmit}
                  >
                    Оформить заказ
                  </button>
                </div>
                {/* Выход */}
                {/*{user &&
                <div className="form__item order__form-item order__form-item_submit"><UserDetails user={user}/></div>}*/}
              </div>
            </div>

          </div>
        </div>

      </div>

    </React.Fragment>
  );
}

export default CheckoutPayType;