import React, { useEffect } from 'react';

function Page404() {
    useEffect(() => {
        document.title = `Страница не найдена | Azimut Hotels`;
    });
    return (
        <div className="content">
            <div className="message message_order-success">
                <div className="cnt message__cnt">
                    <div className="headline message__headline">
                        <h1 className="headline__title">
                            Страница не найдена. Для корректной работы сервиса отсканируйте QR код.
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Page404;