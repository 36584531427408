import React, { useEffect } from 'react';
import {Link} from "react-router-dom";
import Header from "../layout/Header";
import Nav from "../layout/Nav";

function Help() {
    useEffect(() => {
        document.title = `Помощь | AZIMUT Hotels`;
    });

    const headerItem1 = (
        <Link to="/" className="button-back-top">
            <svg className="icon-svg button-back-top__icon">
                <use xlinkHref="#icon-arrow-3"></use>
            </svg>
        </Link>
    );
    return (
        <React.Fragment>

            <Header item1={headerItem1} item2=""/>
            <Nav/>

            <div className="content">
                <div className="post-card">
                    <div className="cnt post-card__cnt">
                        <div className="post-card__part post-card__part_1">
                            <div className="post-card__picture">
                                <img src="/img/pics/5-1.jpg" alt="" title="" className="post-card__picture-img"/>
                            </div>
                            <div className="post-card__part post-card__part_3">
                                <h1 className="post-card__title">
                                    Об интерактивном меню AZIMUT Hotels
                                </h1>
                            </div>
                        </div>
                        <div className="post-card__part post-card__part_2">
                            <div className="post-card__text">
                                <h3>
                                    О сети отелей AZIMUT Hotels
                                </h3>
                                <p>
                                    AZIMUT Hotels — международная сеть отелей, представленная в России, Европе, на
                                    Ближнем Востоке.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </React.Fragment>
    );
}
export default Help;