import React, { useEffect, useState } from 'react';
import Header from '../layout/Header';
import Nav from '../layout/Nav';
import api from "../../utils/api";
import $ from 'jquery';
import Page404 from "./Page404";
import Loading from "./Loading";
import {Link} from "react-router-dom";
import Product from "../blocks/Product";
window.jQuery = window.$ = $;
require('owl.carousel');

function Location(props) {
    const [location, setLocation] = useState(null);
    const [place, setPlace] = useState(null);
    const [categories, setCategories] = useState(null);
    const [goods, setGoods] = useState(null);
    const [page404, setPage404] = useState(false);

    // Загрузка данных
    // Локация
    useEffect(() => {
        api.get(`/locations/${props.locationId}`).then(res=>{
            setLocation(res.data);
        }).catch(err=>{
            setPage404(true);
        })
    },[props.locationId]);
    // Место
    useEffect(() => {
        if (props.placeId) {
            api.get(`/location-places/${props.placeId}`).then(res => {
                setPlace(res.data);
            }).catch(err => {
                setPage404(true);
            })
        }
    }, [props.placeId]);
    // Категории
    useEffect(() => {
        api.get(`/categories`, {params: {locationId: props.locationId, isAdult: props.isAdult, showOnMain: 1}}).then(res=>{
            setCategories(res.data);
        }).catch(err=>{
            setPage404(true);
        })
    },[props.isAdult, props.locationId]);
    // Продукты
    useEffect(() => {
        api.get(`/goods`, {params: {locationId: props.locationId, isAdult: props.isAdult, showOnMain: 1}}).then(res=>{
            setGoods(res.data);
        }).catch(err=>{
            setPage404(true);
        })
    },[props.isAdult, props.locationId]);

    // Title
    useEffect(() => {
        document.title = `Онлайн-Меню, главная страница | Azimut Hotels`;
    });

    // Карусель
    useEffect(() => {
        $('.categories_carousel').each(function(){
            var $box = $(this),
                carousel = {
                    $owl: $('.owl-carousel', $box),
                    options: {
                        $box: $box,
                        mouseDrag: true,
                        dots: false,
                        nav: false,
                        navClass: ['owl-nav-button owl-nav-button_prev', 'owl-nav-button owl-nav-button_next' ],
                        navText: ['<svg class="icon-svg owl-nav-button__icon"><use xlink:href="#icon-arrow-3-l"></use></svg>', '<svg class="icon-svg owl-nav-button__icon"><use xlink:href="#icon-arrow-3-r"></use></svg>'],
                        margin: 16,
                        loop: false,
                        smartSpeed: 400,
                        autoplay: false,
                        items: 2
                    },
                    init: function(){
                        var self = this;

                        self.$owl.on('initialized.owl.carousel', function(event) {
                            $box.addClass('initialized');
                            self.$owl.trigger('refresh.owl.carousel');
                        });

                        self.$owl.owlCarousel(self.options);
                    }
                };

            carousel.init();

        });

        // Размонтирование карусели
        return () => {
        };
    });


    // Шаблоны
    if (page404) {
        return <Page404/>
    } else if (!location || (props.placeId && !place) || !categories || !goods) {
        return <Loading/>
    }

    const headerItem1 = (
        <span className="location-top">
            <svg className="icon-svg location-top__icon">
                <use xlinkHref="#icon-location"></use>
            </svg>
            <div className="location-top__text">
                {location.name}{place ? `: ${place.name}` : ''} {location.minOrderPrice > 0 ?  `(минимальный заказ — ${location.minOrderPrice} ₽)` : ''}
            </div>
        </span>
    );

    let headerItem2 = '';
    if (location.phone) {
        headerItem2 = (
            <div className="contacts-top">
                <a href={`tel:${location.phone}`} className="contacts-top__phone">
                    <svg className="icon-svg contacts-top__phone-icon">
                        <use xlinkHref="#icon-phone"></use>
                    </svg>
                </a>
            </div>
        );
    }
    return (
        <React.Fragment>

            <Header item1={headerItem1} item2={headerItem2}/>
            <Nav/>

            <div className="content">
                {/* Заголовок */}
                <div className="headline">
                    <div className="cnt headline__cnt">
                        <h1 className="headline__title">
                            Меню
                        </h1>
                    </div>
                </div>

                {/* Разделы */}
                <div className="categories categories_carousel categories_margin-1">
                    <div className="cnt categories__cnt">
                        <div className="categories__head">
                            <div className="title categories__title">
                                Разделы
                            </div>
                            <div className="categories__button-more">
                                <Link to="/catalog" className="link-a categories__button-more-link">
                                    Все
                                    <svg className="icon-svg link-a__icon">
                                        <use xlinkHref="#icon-arrow-2"></use>
                                    </svg>
                                </Link>
                            </div>
                        </div>

                        {/* Список разделов */}
                        <div className="categories__grid categories__grid_1 owl-carousel">
                            {categories.map(cat => {
                                return <div className="categories__grid-item" key={cat.id}>
                                    <Link to={`/catalog/${cat.id}`} className="category-mini">
                                        <div className="category-mini__picture">
                                            <img src={cat.image} alt={cat.name} title={cat.name}
                                                 className="category-mini__picture-img"/>
                                        </div>
                                        <div className="category-mini__data">
                                            <div className="category-mini__title">
                                                {cat.name}
                                            </div>
                                            {cat.goodsCount > 0 &&
                                                <div className="category-mini__detail">
                                                    {cat.goodsCount} позиции
                                                </div>
                                            }
                                        </div>
                                    </Link>
                                </div>
                            })}
                        </div>
                    </div>
                </div>

                {/* Продукты */}
                {goods.length > 0 &&
                    <div className="products">
                        <div className="cnt products__cnt">
                            <div className="products__head">
                                <div className="title products__title">
                                    Новинки
                                </div>
                            </div>
                            <div className="products__grid products__grid_1">

                                {/* Список продуктов */}
                                {goods.map(goods => {
                                    return <Product goods={goods} key={goods.id}/>
                                })}

                            </div>
                        </div>
                    </div>
                }

            </div>
        </React.Fragment>
    );
}
export default Location;