import React, {useEffect, useState} from 'react';
import {Link, Redirect} from "react-router-dom";
import Header from "../layout/Header";
import Nav from "../layout/Nav";
import api from "../../utils/api";
import Page404 from "./Page404";
import Loading from "./Loading";
import {useHistory} from "react-router-dom";
import {useUser} from 'reactfire';

function CabinetOrders() {
    const [orders, setOrders] = useState(null);
    const [page404, setPage404] = useState(false);
    const history = useHistory();
    const {status, data: user, hasEmitted} = useUser();

    useEffect(() => {
        document.title = `Заказы | Azimut Hotels`;
    }, []);
    // useEffect(() => {
    //     if (!user) {
    //         history.push("/checkout/contact");
    //     }
    // }, []);

    // Загрузка данных
    // Заказы
    useEffect(() => {
        api.get(`/orders`).then(res => {
            setOrders(res.data.data);
        }).catch(err => {
            console.log(err)
            setPage404(true);
        })

    }, []);

    if (!user) {
        return <Redirect to="/checkout/contact"/>
    }

    // Шаблоны
    if (page404) {
        return <Page404/>
    } else if (!orders) {
        return <Loading/>
    }

    const headerItem1 = (
        <Link to="/" className="button-back-top">
            <svg className="icon-svg button-back-top__icon">
                <use xlinkHref="#icon-arrow-3"/>
            </svg>
        </Link>
    );
    return (
        <React.Fragment>

            <Header item1={headerItem1} item2=""/>
            <Nav/>
            <div className="content">
                <div className="orders">
                    <div className="cnt orders__cnt">
                        <div className="headline orders__headline">
                            <h1 className="headline__title orders__title">
                                Заказы
                            </h1>
                        </div>


                        {/* Продукты */}
                        {(orders && orders.length) > 0
                            ?
                            <div className="orders__grid orders__grid_1">
                                {/* Список продуктов */}
                                {orders.map(order => {
                                    return <div className="orders__grid-item" key={order.id}>
                                        <Link to={`/cabinet/orders/${order.id}`} className="order-mini">
                                            <div className="order-mini__grid order-mini__grid_1">
                                                <div className="order-mini__grid-item order-mini__grid-item_1">
                                                    <div className="order-mini__number">
                                                        № {order.id}
                                                    </div>
                                                    <div className="order-mini__room">
                                                        {order.placeText}
                                                    </div>
                                                    <div className="order-mini__date">
                                                        {(new Date(order.created_at)).toLocaleDateString('ru-RU', {
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric',
                                                            hour: 'numeric',
                                                            minute: 'numeric'
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="order-mini__grid-item order-mini__grid-item_2">
                                                    <div className="order-mini__price">
                                                        {order.priceAmount} ₽
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                })}
                            </div>
                            :
                            <div className="message message_cart-empty">
                                <div className="cnt message__cnt">
                                    <div className="headline message__headline">
                                        <h2 className="headline__title">
                                            У Вас пока нет заказов.
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            {/* Страница находится в разработке. */}
            {/*<div className="content">
          <div className="product-card">
              <div className="cnt product-card__cnt">
                  <div className="product-card__part product-card__part_1">
                      <div className="product-card__picture">
                          <img src={`${process.env.REACT_APP_SITE_URL}images/azm_c.jpg`}
                               alt=""
                               title=""
                               className="product-card__picture-img"/>
                      </div>
                      <div className="product-card__part product-card__part_3"><h1
                          className="product-card__title">Страница в разработке</h1></div>
                  </div>
                  <div className="product-card__part product-card__part_2">
                      <div className="product-card__description">Страница находится в разработке.</div>
                  </div>

              </div>
          </div>
      </div>*/}
        </React.Fragment>
    );
}

export default CabinetOrders;