import React, { useEffect } from 'react';

function IsAdult(props) {
    useEffect(() => {
        document.title = `Подтверждение 18 лет | Azimut Hotels`;
    });
    return (
        <div className="content">
            <div className="message message_age">
                <div className="cnt message__cnt">
                    <div className="message__picture">
                        <div className="message__picture-circle">
                            <div className="message__picture-circle-text">
                                18+
                            </div>
                        </div>
                    </div>

                    <div className="message__text">
                        <h2>
                            Вам уже исполнилось 18 лет?
                        </h2>

                        <p>
                            Некоторые разделы меню предназначены для просмотра исключительно лицам достигшим
                            совершеннолетия!
                        </p>
                    </div>

                    <div className="message__buttons">
                        <div className="message__buttons-item">
                            <button className="button-a message__buttons-item-link"
                                    onClick={(e) => props.onChangeIsAdult('1', e)}
                            >
                                Да, мне есть 18 лет
                            </button>
                        </div>
                        <div className="message__buttons-item">
                            <button
                               className="button-a button-a_bg-3 message__buttons-item-link"
                               onClick={(e) => props.onChangeIsAdult('0', e)}
                            >
                                Нет, я моложе 18 лет
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        /*<div className="content">
            Вам уже исполнилось 18 лет?
            <br/>
            <button onClick={(e) => props.onChangeIsAdult('1', e)}>Да</button>
            <button onClick={(e) => props.onChangeIsAdult('0', e)}>Нет</button>
        </div>*/
    );
}
export default IsAdult;