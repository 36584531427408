import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AppAxiosAuth from './AppAxiosAuth';
import 'firebase/auth';
import { FirebaseAppProvider } from 'reactfire';

const firebaseConfig = {
  apiKey: "AIzaSyBPmGyKAcheabpPiAi90onE-YJKyxdX1Qw",
  authDomain: "azimut-menu.firebaseapp.com",
  projectId: "azimut-menu",
  storageBucket: "azimut-menu.appspot.com",
  messagingSenderId: "628193103153",
  appId: "1:628193103153:web:af689b5fb0df3f1ef9b410"
};

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <AppAxiosAuth>
        <App />
      </AppAxiosAuth>
    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);